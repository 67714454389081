import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { useSiteMetadata } from '@hooks/use-site-metadata'
import { useIntl } from '@intl'

const Metadata = ({ title, description, banner }) => {
  const {
    siteMetadata: {
      banner: {
        url: siteBannerUrl,
        width: siteBannerWidth,
        height: siteBannerHeight,
      },
      url: siteUrl,
    },
  } = useSiteMetadata()

  const {
    messages: {
      metadata: { title: siteTitle, description: siteDescription },
    },
  } = useIntl()

  const metaTitle = title ? `${title} | ${siteTitle}` : siteTitle
  const metaDescription = description || siteDescription
  const metaBanner = banner ? `${siteUrl}${banner}` : siteBannerUrl

  return (
    <Helmet>
      {/* General */}
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="image" content={metaBanner} />

      {/* Open Graph */}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaBanner} />
      <meta property="og:image:width" content={siteBannerWidth} />
      <meta property="og:image:height" content={siteBannerHeight} />
      <meta property="og:type" content="website" />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaBanner} />
    </Helmet>
  )
}

Metadata.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  banner: PropTypes.string,
}

Metadata.defaultProps = {
  title: '',
  description: '',
  banner: '',
}

export default Metadata
